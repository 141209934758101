<template>
  <b-modal
    size="xl"
    id="finQueryModal"
    title="Select a report to load"
    modal-class="std-ultra-wide-modal"
    header-class="px-3 pt-3 pb-2"
    ref="finQueryModal"
    @show="loadInitData()"
    no-close-on-backdrop
    no-close-on-esc
    hide-footer
  >
    <template slot="modal-header">
      <h5 class="modal-title">
        {{
          isUpdateMode
            ? `Edit Group: ${this.queryName}`
            : 'Create Group using FIN Query'
        }}
      </h5>
      <button type="button" class="close" @click.prevent="closeModal">x</button>
    </template>

    <div :class="{ processing: fetchingFIs }">
      <v-wait for="loadingQueryData">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <div class="row no-gutters mb-2">
            <div class="offset-sm-9 col-sm-3 std-border p-2 fi-count-component">
              <div class="d-flex align-items-center">
                <div>
                  <span class="pr-2">
                    <i class="fa fa-university fa-3x"></i>
                  </span>
                </div>
                <div class="" v-if="fiCount !== null">
                  <div v-if="fetchingFIs">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <div v-else>
                    <h4 class="mb-0">
                      <b>{{ fiCount | numberFormat }}</b>
                    </h4>
                  </div>
                  <h6 class="mb-0">
                    Matching {{ fiCount | pluralize('Institution') }}
                  </h6>
                </div>
                <div v-else>
                  <div v-if="fetchingFIs">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <div v-else>
                    <h4 class="mb-0">
                      <b> - </b>
                    </h4>
                  </div>
                  <h6 class="mb-0">
                    Matching Institutions
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <query-group
            :rules="queryRules"
            :query="query"
            @updateQuery="updateQuery"
            @clearResult="resetFIData"
          ></query-group>
          <div class="row">
            <div class="col-sm-8 offset-sm-2">
              <div class="row">
                <div class="col-sm-8 offset-sm-2">
                  <div class="row">
                    <div class="col-sm-12 pb-1">
                      <b-form-group>
                        <label for="Search" class="mb-1">
                          <b>Group Name</b
                          ><i class="pl-1">(required on save)</i>
                        </label>
                        <b-form-input
                          type="text"
                          name="query_name"
                          class="form-control rounded"
                          placeholder="Enter a Group Name"
                          v-model="queryName"
                          ref="queryName"
                          :class="[
                            $v.queryName.$dirty && $v.queryName.$invalid
                              ? 'is-invalid'
                              : '',
                            'form-control rounded'
                          ]"
                        ></b-form-input>
                        <b-form-invalid-feedback
                          v-if="$v.queryName.$dirty && $v.queryName.$invalid"
                          >group name required to save</b-form-invalid-feedback
                        >
                        <div
                          class="invalid-feedback d-block info-msg"
                          v-if="showWarning"
                        >
                          <span class="fa fa-info-circle pr-1"></span>
                          changing name will save this as new group
                        </div>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 offset-sm-2">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Description</b><i class="pl-1">(Optional)</i>
                    </label>
                    <b-form-textarea
                      id="description"
                      class="form-control rounded"
                      v-model="queryDescription"
                      rows="2"
                      max-rows="2"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 offset-sm-2">
                  <b-form-group>
                    <label for="Search" class="mb-1">
                      <b>Group Access</b><i class="pl-1">(Optional)</i>
                    </label>

                    <multiselect
                      v-model="selectedGroups"
                      :options="userGroups"
                      :multiple="true"
                      :close-on-select="false"
                      label="name"
                      track-by="id"
                      :showLabels="false"
                      placeholder
                      class="simple-select"
                      :searchable="false"
                    >
                      <template slot="selection" slot-scope="{ values }"
                        >{{ values.length }}
                        {{ values.length | pluralize('group') }}
                        selected</template
                      >
                      <template slot="option" slot-scope="group">
                        <div>
                          <input
                            type="checkbox"
                            :checked="isGroupSelected(group.option)"
                            class="mr-1"
                          />
                          {{ group.option.name }}
                        </div>
                      </template>
                    </multiselect>
                  </b-form-group>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-12 d-flex justify-content-center mt-3 mb-3">
            <div class="px-1" v-if="saveAsMode || !isEditMode">
              <button
                type="button"
                class="btn fw-btn btn-success rounded"
                v-b-modal.savedQueriesList
              >
                Load Query
              </button>
            </div>
            <div class="px-1 d-flex flex-column">
              <div>
                <button
                  type="button"
                  :class="['btn fw-btn btn-success rounded']"
                  @click="saveQuery()"
                >
                  Save Group
                </button>
              </div>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn fw-btn btn-secondary rounded"
                @click="resetQuery"
              >
                Reset Query
              </button>
            </div>
            <div class="px-1">
              <button
                type="button"
                class="btn fw-btn btn-primary rounded"
                @click="executeQuery"
              >
                Run Query
              </button>
            </div>
          </div>
        </div>

        <saved-queries-modal
          @resetQueryRules="resetQuery"
          @querySelected="loadSelectedQuery"
          renderedIn="Industry Reporter"
          :fiType="groupFIType.value"
        ></saved-queries-modal>
      </v-wait>
    </div>
  </b-modal>
</template>

<script>
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { EventBus } from '@/plugins/events.js'
import { findDeep } from 'deepdash'
import _ from 'lodash'
// ui components
import Multiselect from 'vue-multiselect'
import QueryGroup from '@/modules/advanced_query/components/QueryGroup'
import SavedQueriesModal from '@/modules/advanced_query/components/SavedQueriesModal'
//utilities
import deepClone from '@/utilities.js'
//validations
import { required } from 'vuelidate/lib/validators'
// helpers
import QueryTransformer from '@/modules/advanced_query/helpers/query.transformer.js'
import QueryRetransformer from '@/modules/advanced_query/helpers/query.retransformer.js'
// api
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'

export default {
  name: 'IRFINQueryModal',
  components: {
    QueryGroup,
    Multiselect,
    SavedQueriesModal
  },
  mounted() {
    let vm = this
    EventBus.$on('countUpdated', ruleDetail => {
      let matchingRule = findDeep(
        vm.query.children,
        function(rule) {
          return rule.id === ruleDetail.id
        },
        { childrenPath: 'children' }
      )

      if (matchingRule) {
        let queryRule = matchingRule.value

        queryRule.loading = false
        queryRule.count = ruleDetail.count
      }
    })
  },
  data() {
    return {
      query: null,
      saveAsMode: false,
      queryName: null,
      queryStateOnLoad: null
    }
  },
  computed: {
    ...mapState('AdvancedQuery', {
      fiCount: state => state.fiCount,
      baseQuery: state => state.baseQuery,
      queryRules: state => state.queryRules,
      fetchingFIs: state => state.fetchingFIs,
      isValidQuery: state => state.isValidQuery,
      fiResultType: state => state.fiResultType,
      selectedQuery: state => state.selectedQuery,
      individualCount: state => state.individualCount
    }),
    ...mapGetters('AdvancedQuery', ['isMortgageLender']),
    ...mapState('Authentication', {
      userGroups: state => state.userGroups
    }),
    ...mapState('IndustryReporter', {
      groupFIType: state => state.groupFIType
    }),
    ...mapFields('AdvancedQuery', {
      queryGroupIDs: 'selectedQuery.group_ids',
      queryDescription: 'selectedQuery.description'
    }),
    selectedGroups: {
      get() {
        return this.userGroups.filter(ug => {
          return this.queryGroupIDs.includes(ug.id)
        })
      },
      set(groups) {
        this.setQueryGroupIDs(groups.map(g => g.id))
      }
    },
    validForSave() {
      return this.isValidQuery && !this.$v.queryName.$invalid
    },
    isEditMode() {
      return this.selectedQuery.id
    },
    isUpdateMode() {
      return this.isEditMode && !this.saveAsMode
    },
    isQueryNameUnchanged() {
      return (
        this.selectedQuery.name.toLowerCase().trim() ===
        this.queryName.toLowerCase().trim()
      )
    },
    showWarning() {
      return this.isUpdateMode && this.queryName && !this.isQueryNameUnchanged
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', [
      'fetchQueryRules',
      'fetchFICount'
      //  'loadEnterprisePermissions'
    ]),
    ...mapMutations('AdvancedQuery', [
      'setFICount',
      'resetQueryData',
      'setQueryValidity',
      'setSelectedQuery',
      'setQueryGroupIDs',
      'resetSelectedQuery',
      'setProcessingStatus',
      'resetFICountAndPriceData',
      'setIsMortgageLender'
    ]),
    loadInitData() {
      this.$wait.start('loadingQueryData')
      if (this.isMortgageLender) {
        this.setIsMortgageLender(false)
      }
      this.saveAsMode = false
      this.queryName = this.isEditMode ? this.selectedQuery.name : null
      //this.loadEnterprisePermissions()
      this.fetchQueryRules()
        .then(() => {
          if (this.isEditMode) {
            this.query = new QueryRetransformer().retransform
            this.resetFICountAndPriceData()

            this.calculateQueryValidity()

            this.$nextTick(() => {
              this.fetchFICount(this.query).then(() => {
                this.updateIndividualCount(this.query)
                this.queryStateOnLoad = deepClone(this.query)
              })
            })
          } else {
            this.query = deepClone(this.baseQuery)
            this.queryStateOnLoad = deepClone(this.query)
          }
        })
        .finally(() => {
          this.$wait.end('loadingQueryData')
        })
    },
    updateQuery(query) {
      this.query = query
      this.resetFIData()
    },
    resetFIData() {
      this.setFICount(null)
    },
    calculateQueryValidity() {
      this.setQueryValidity(true)
      this.checkQueryValidity(this.query.children)
    },
    checkQueryValidity(queryRules) {
      for (let rule of queryRules) {
        if (rule.children) {
          this.checkQueryValidity(rule.children)
        } else {
          if (
            rule.value.length === 0 ||
            (rule.value.length > 0 &&
              [undefined, ''].includes(rule.value[0])) ||
            (Array.isArray(rule.value[0]) && !rule.value[0].length)
          ) {
            this.setQueryValidity(false)
            rule.error = true
          } else {
            rule.error = false
          }
        }
      }
    },
    updateIndividualCount(query) {
      if (query.type === 'group') {
        query.children.forEach(rule => {
          this.updateIndividualCount(rule)
        })
      } else {
        query.count = this.individualCount[query.id]
      }
    },
    validateAndRunQuery() {
      this.$v.$touch()
      this.calculateQueryValidity()
      if (this.fiCount) {
        //  this.$v.$touch()
        return this.validForSave ? Promise.resolve() : Promise.reject()
      } else {
        //this.$v.$touch()
        //this.calculateQueryValidity()

        if (this.isValidQuery) {
          return this.fetchFICount(this.query).then(() => {
            this.updateIndividualCount(this.query)
            return this.validForSave ? Promise.resolve() : Promise.reject()
          })
        } else {
          this.$toasted.global.invalid(
            'Invalid Query, please fill in blank sections.'
          )
          return Promise.reject()
        }
      }
    },
    resetQuery() {
      if (!this.isEditMode) {
        this.resetSelectedQuery()
      }
      this.query = deepClone(this.baseQuery)
      this.$v.$reset()
      this.resetFIData()
    },
    closeModal() {
      if (this.hasUnsavedChanges()) {
        return this.$dialog
          .confirm(
            {
              title: 'Alert',
              body: 'Changes not saved, do you want to save?'
            },
            {
              okText: 'Yes',
              cancelText: 'No'
            }
          )
          .then(() => {
            this.$nextTick(() => {
              this.saveQuery()
            })
          })
          .catch(() => {
            this.forceCloseModal()
          })
      } else {
        this.forceCloseModal()
      }
    },
    forceCloseModal() {
      this.resetQueryData()
      this.resetFICountAndPriceData()
      this.setIsMortgageLender(false)
      this.$v.$reset()
      this.$emit('queryModalClosed')
      this.$bvModal.hide('finQueryModal')
    },
    executeQuery() {
      this.calculateQueryValidity()

      if (this.isValidQuery) {
        this.fetchFICount(this.query).then(() => {
          this.updateIndividualCount(this.query)
        })
      } else {
        this.$toasted.global.invalid(
          'Invalid Query, please fill in blank sections.'
        )
      }
    },
    saveQuery() {
      this.isUpdateMode && this.isQueryNameUnchanged
        ? this.updateFINQuery()
        : this.createFINQuery()
    },
    createFINQuery() {
      this.validateAndRunQuery().then(
        () => {
          this.setProcessingStatus(true)

          let query = new QueryTransformer(this.query).transformToSave
          return advancedQueryAPI
            .saveQuery(
              this.queryName,
              this.queryDescription,
              this.queryGroupIDs,
              query,
              this.fiResultType
            )
            .then(res => {
              if (res) {
                this.setSelectedQuery(res.query)
                this.$toasted.global.action_success('Query successfully saved.')
                this.$emit('queryCreated', res.query)
                this.forceCloseModal()
              }
              this.setProcessingStatus(false)
            })
        },
        () => {}
      )
    },
    updateFINQuery() {
      this.validateAndRunQuery().then(
        () => {
          this.setProcessingStatus(true)

          let query = new QueryTransformer(this.query).transformToSave
          return advancedQueryAPI
            .updateQuery(this.selectedQuery, query, this.fiResultType)
            .then(res => {
              if (res && res.success) {
                this.$toasted.global.action_success(
                  'Query successfully updated.'
                )
                this.$emit('queryUpdated', res.query)
                this.forceCloseModal()
              }
              this.setProcessingStatus(false)
            })
        },
        () => {}
      )
    },
    loadSelectedQuery() {
      this.saveAsMode = true
      this.queryName = this.selectedQuery.name

      this.query = new QueryRetransformer().retransform
      this.resetFICountAndPriceData()
      this.fetchFICount(this.query).then(() => {
        this.updateIndividualCount(this.query)
      })
    },
    hasUnsavedChanges() {
      return !_.isEqual(this.query, this.queryStateOnLoad)
    },
    isGroupSelected(option) {
      return this.queryGroupIDs.includes(option.id)
    }
  },
  validations: {
    queryName: {
      required
    }
  }
}
</script>

<style lang="scss" scoped>
.fi-count-component {
  background-color: #f5f5f5;
}
</style>
